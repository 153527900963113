import React from "react";
import { MDBAnimation, MDBModal, MDBModalBody } from "mdbreact";
import { MDBCarousel, MDBCarouselCaption, MDBCarouselInner, MDBCarouselItem, MDBView, MDBMask, MDBContainer } from "mdbreact";
import Lightbox from "react-image-lightbox";
import moment from "moment";
const images = [
    "images/assets/Submit - Poster 2 kcal.jpg",
    "images/assets/Submit - Poster WPI.jpg",

];

const imagesmid = [
    "images/assets/S__115786235.jpg",
    "images/assets/1634183202004.jpg",

];
const imgProductInfo = ["images/assets/Smofkabiven for Pediatric 80x200cm KS 0306-2563-01.jpg"];







class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenProductInfo: false,
            isOpenGetToKnow: false,
            isOpenHandle: false,
            isOpenClip: false,
            isOpenRigthload: false,
            isOpenleftload: false,
            isOpenindex: true,

            isOpenClipmiddle01: false,
            isOpenClipmiddle02: false,
            isOpenClipmiddleimg01: false,
            isOpenClipleft01: false,
            isOpenClipright01: false,
            isOpenClipright02: false,

            isOpenleftimage01: false,
            isOpenleftimage02: false,
            isOpenleftimage03: false,
            isOpenrightimage01: false,
            isOpenrightimage02: false,
            isOpenrightimage03: false,
            timeText: "",
            photoIndex: 0,
            photoIndexProduct: 0,
            photoIndexleftimage01: 0,
            photoIndexleftimage02: 0,
            photoIndexleftimage03: 0,
            photoIndexleftimage04: 0,
            photoIndexleftimage05: 0,
            photoIndexleftimage06: 0,
            isOpenPortfolio: false,
            isOpenmidicon: false,
            isFullScreen: false,
            isState: 0,
        };
    }
    componentDidMount() {
        let timeNow = new Date();
        let timeNowH = moment(timeNow).format("HH");
        let timeNowHInt = parseInt(timeNowH);
        //let timeNowHInt = 16;
        if (timeNowHInt < 12 && timeNowHInt > 6) {
            this.setState({ timeText: "morning" });
            //console.log("morning");
        } else if (timeNowHInt < 18 && timeNowHInt > 12) {
            this.setState({ timeText: "afternoon" });
            //console.log("afternoon");
        } else {
            this.setState({ timeText: "night" });
            //console.log("night");
        }
    }
    componentWillUnmount() {}

    toggleGetToKnow = () => {
        this.setState({ isOpenGetToKnow: !this.state.isOpenGetToKnow });
    };
    togglePortfolio = () => {
        this.setState({ isOpenPortfolio: !this.state.isOpenPortfolio });
    };

    togglemidicon = () => {
        this.setState({ isOpenmidicon: !this.state.isOpenmidicon });
    };

    toggleProductInfo = () => {
        this.setState({ isOpenProductInfo: !this.state.isOpenProductInfo });
    };
    toggleHandle = () => {
        this.setState({ isOpenHandle: !this.state.isOpenHandle });
    };
    toggleClip = () => {
        this.setState({ isOpenClip: !this.state.isOpenClip });
    };

    toggleisOpenindex = () => {
        this.setState({ isOpenindex: !this.state.isOpenindex });
    };



    toggleClipmiddle01 = () => {
        this.setState({ isOpenClipmiddle01: !this.state.isOpenClipmiddle01 });
    };
    toggleClipmiddle02 = () => {
        this.setState({ isOpenClipmiddle02: !this.state.isOpenClipmiddle02 });
    };
    toggleClipmiddleimg01 = () => {
        this.setState({ isOpenClipmiddleimg01: !this.state.isOpenClipmiddleimg01 });
    };



    toggleClipleft01 = () => {
        this.setState({ isOpenClipleft01: !this.state.isOpenClipleft01 });
    };

    toggleClipright01 = () => {
        this.setState({ isOpenClipright01: !this.state.isOpenClipright01 });
    };

    toggleClipright02 = () => {
        this.setState({ isOpenClipright02: !this.state.isOpenClipright02 });
    };

    toggleisOpenRigthload = () => {
        this.setState({ isOpenRigthload: !this.state.isOpenRigthload });
    };

    toggleisOpenleftload = () => {
        this.setState({ isOpenleftload: !this.state.isOpenleftload });
    };

    toggleleftimage01 = () => {
        this.setState({ isOpenleftimage01: !this.state.isOpenleftimage01 });
    };

    toggleleftimage02 = () => {
        this.setState({ isOpenleftimage02: !this.state.isOpenleftimage02 });
    };
    toggleleftimage03 = () => {
        this.setState({ isOpenleftimage03: !this.state.isOpenleftimage03 });
    };

    togglerighttimage01 = () => {
        this.setState({ isOpenrightimage01: !this.state.isOpenrightimage01 });
    };
    togglerighttimage02 = () => {
        this.setState({ isOpenrightimage02: !this.state.isOpenrightimage02 });
    };
    togglerighttimage03 = () => {
        this.setState({ isOpenrightimage03: !this.state.isOpenrightimage03 });
    };



    toggleFullScreen = () => {
        var doc = window.document;
        var docEl = doc.documentElement;

        var requestFullScreen =
            docEl.requestFullscreen ||
            docEl.mozRequestFullScreen ||
            docEl.webkitRequestFullscreen ||
            docEl.msRequestFullscreen;
        var cancelFullScreen =
            doc.exitFullscreen ||
            doc.mozCancelFullScreen ||
            doc.webkitExitFullscreen ||
            doc.msExitFullscreen;

        if (!doc.fullscreenElement &&
            !doc.mozFullScreenElement &&
            !doc.webkitFullscreenElement &&
            !doc.msFullscreenElement
        ) {
            requestFullScreen.call(docEl);
        } else {
            cancelFullScreen.call(doc);
        }
    };
    render() {
        return ( <
            >
            <
            div className = "checkLandscape" >
            <
            p > กรุณาเข้ าชมเว็ บไซต์ ในแนวนอน < /p> <
            p > Open website in landscape < /p> <
            /div>

            <
            div className = "mainContainVirtual" >

            <
            div className = "screenStage" >

            {
                this.state.isState === 0 && (

                    <
                    >
                    <
                    img src = "images/backgrounds/stage_main.jpg"
                    alt = "stage main"
                    className = "imgScreenStage" /
                    >


                    <
                    MDBAnimation type = "flash"
                    duration = "6.2s"
                    infinite = { true } >

                    <
                    img src = "images/button/Button_Kabi-left.png"
                    className = "btn_Kabi_left btn_fix"
                    alt = "button"
                    onClick = {
                        () => this.setState({ isState: 2 }) }
                    />

                    <
                    /MDBAnimation>

                    <
                    MDBAnimation type = "flash"
                    duration = "6.2s"
                    infinite = { true } >

                    <
                    img src = "images/button/Button_Kabi-middle.png"
                    className = "btn_Kabi_middle"
                    alt = "button"
                    onClick = {
                        () => this.setState({ isState: 1 }) }
                    />

                    <
                    /MDBAnimation> <
                    MDBAnimation type = "flash"
                    duration = "6.2s"
                    infinite = { true } >

                    <
                    img src = "images/button/Button_Kabi-right.png"
                    className = "btn_Kabi_right"
                    alt = "button"
                    onClick = {
                        () => this.setState({ isState: 3 }) }

                    />

                    <
                    /MDBAnimation>

                    <
                    />
                )
            }

            {
                this.state.isState === 1 && ( <
                    >
                    <
                    img src = "images/backgrounds/stage_middle.jpg"
                    alt = "stage middle"
                    className = "imgScreenStage" /
                    >



                    <
                    a href = " https://in.unitedforclinicalnutrition.com
                    "
                    target = "_blank"
                    rel = "noopener noreferrer" >
                    <
                    img src = "images/button/Icon_Kabi-04.png"
                    className = "btn_Kabi_md_ex_01 btn_fix"
                    alt = "button" /
                    >
                    <
                    /a>




                    <
                    img src = "images/button/Icon_Kabi-02.png"
                    className = "btn_Kabi_md_ex_02 btn_fix"
                    alt = "button"
                    onClick = { this.togglemidicon }
                    />





                    <
                    a href = " https://www.fresenius-kabi.com
                    "
                    target = "_blank"
                    rel = "noopener noreferrer" >
                    <
                    img src = "images/button/Icon_Kabi-03.png"
                    className = "btn_Kabi_md_ex_03 btn_fix"
                    alt = "button" /
                    >
                    <
                    /a>




                    <
                    img src = "images/button/Button_Kabi-Play-mid.png"
                    className = "btn_Kabi_md_01 btn_fix"
                    alt = "button"
                    id = "btn_Kabi_md_01"
                    onClick = { this.toggleClipmiddle01 }
                    />



                    <
                    img src = "images/button/Button_Kabi-Play-mid-2.png"
                    className = "btn_Kabi_md_02 btn_fix"
                    alt = "button"
                    onClick = { this.toggleClipmiddle02 }
                    />



                    <
                    img src = "images/button/Button_Kabi-01.png"
                    className = "btn_Kabi_md_left btn_fix"
                    alt = "button"
                    onClick = {
                        () => this.setState({ isState: 2 }) }
                    />



                    <
                    img src = "images/button/Button_Kabi-02.png"
                    className = "btn_Kabi_md_right btn_fix"
                    alt = "button"
                    onClick = {
                        () => this.setState({ isState: 3 }) }
                    />

                    <
                    />

                )
            }

            {
                this.state.isState === 2 && ( <
                    >
                    <
                    img src = "images/backgrounds/stage_left.jpg"
                    alt = "stage left"
                    className = "imgScreenStage" /
                    >

                    <
                    img src = "images/button/Button_Kabi-02.png"
                    className = "btn_Kabi_md_right btn_fix"
                    alt = "button"
                    onClick = {
                        () => this.setState({ isState: 1 }) }
                    />



                    <
                    img src = "images/button/Button_Kabi-05.png"
                    className = "btn_Kabi_lf_donwload btn_fix"
                    alt = "button"
                    onClick = { this.toggleisOpenleftload }

                    />




                    <
                    img src = "images/button/Button_Kabi-lelf-adult.png"
                    className = "btn_Kabi_lf_01 btn_fix"
                    alt = "button"
                    onClick = { this.toggleleftimage01 }
                    />



                    <
                    img src = "images/button/Button_Kabi-left-Parenteral.png"
                    className = "btn_Kabi_lf_02 btn_fix"
                    alt = "button"
                    onClick = { this.toggleleftimage02 }
                    />



                    <
                    img src = "images/button/Button_Kabi-Play-left.png"
                    className = "btn_Kabi_lf_03 btn_fix"
                    alt = "button"
                    onClick = { this.toggleClipleft01 }
                    />



                    <
                    img src = "images/button/Button_Kabi-left-Pediatric.png"
                    className = "btn_Kabi_lf_04 btn_fix"
                    alt = "button"
                    onClick = { this.toggleleftimage03 }
                    />


                    <
                    />
                )
            }

            {
                this.state.isState === 3 && ( <
                    >
                    <
                    img src = "images/backgrounds/stage_right.jpg"
                    alt = "stage right"
                    className = "imgScreenStage" /
                    >


                    <
                    img src = "images/button/Button_Kabi-01.png"
                    className = "btn_Kabi_md_left btn_fix"
                    alt = "button"
                    onClick = {
                        () => this.setState({ isState: 1 }) }
                    />




                    <
                    img src = "images/button/Button_Kabi-right-Fresubin2kcal.png"
                    className = "btn_Kabi_right_01 btn_fix"
                    alt = "button"
                    onClick = { this.togglePortfolio }

                    />



                    <
                    img src = "images/button/Button_Kabi-Play-right.png"
                    className = "btn_Kabi_right_02 btn_fix"
                    alt = "button"
                    onClick = { this.toggleClipright01 }

                    />





                    <
                    img src = "images/button/Button_Kabi-right-nephrology.png"
                    className = "btn_Kabi_right_04 btn_fix"
                    alt = "button"
                    onClick = { this.togglerighttimage02 }

                    />



                    <
                    img src = "images/button/Button_Kabi-right-ketosteril.png"
                    className = "btn_Kabi_right_05 btn_fix"
                    alt = "button"
                    onClick = { this.togglerighttimage03 }
                    />


                    <
                    img src = "images/button/Button_Kabi-Play-right-2.png"
                    className = "btn_Kabi_right_06 btn_fix"
                    alt = "button"
                    onClick = { this.toggleClipright02 }
                    />



                    <
                    img src = "images/button/Button_Kabi-06.png"
                    className = "btn_Kabi_right_07 btn_fix"
                    alt = "button"
                    onClick = { this.toggleisOpenRigthload }
                    />


                    <
                    />

                )
            }


            <
            /div>


            {
                this.state.isOpenleftimage03 && ( <
                    Lightbox mainSrc = { imgProductInfo[this.state.photoIndexProduct] }
                    onCloseRequest = {
                        () => this.setState({ isOpenleftimage03: false }) }
                    />
                )
            }



            {
                this.state.isOpenPortfolio && ( <
                    Lightbox mainSrc = { images[this.state.photoIndex] }
                    nextSrc = { images[(this.state.photoIndex + 1) % images.length] }
                    prevSrc = {
                        images[
                            (this.state.photoIndex + images.length - 1) % images.length
                        ]
                    }
                    onCloseRequest = {
                        () => this.setState({ isOpenPortfolio: false }) }
                    onMovePrevRequest = {
                        () =>
                        this.setState({
                            photoIndex:
                                (this.state.photoIndex + images.length - 1) % images.length,
                        })
                    }
                    onMoveNextRequest = {
                        () =>
                        this.setState({
                            photoIndex: (this.state.photoIndex + 1) % images.length,
                        })
                    }
                    />
                )
            }



            {
                this.state.isOpenmidicon && ( <
                    Lightbox mainSrc = { imagesmid[this.state.photoIndex] }
                    nextSrc = { imagesmid[(this.state.photoIndex + 1) % imagesmid.length] }
                    prevSrc = {
                        imagesmid[
                            (this.state.photoIndex + imagesmid.length - 1) % imagesmid.length
                        ]
                    }
                    onCloseRequest = {
                        () => this.setState({ isOpenmidicon: false }) }
                    onMovePrevRequest = {
                        () =>
                        this.setState({
                            photoIndex:
                                (this.state.photoIndex + imagesmid.length - 1) % imagesmid.length,
                        })
                    }
                    onMoveNextRequest = {
                        () =>
                        this.setState({
                            photoIndex: (this.state.photoIndex + 1) % imagesmid.length,
                        })
                    }
                    />
                )
            }



            <
            MDBModal isOpen = { this.state.isOpenGetToKnow }
            toggle = { this.toggleGetToKnow }
            size = "lg" >
            <
            MDBModalBody >
            <
            div className = "embed-responsive embed-responsive-16by9" > {
                this.state.isOpenGetToKnow && ( <
                    iframe title = "embedsPage"
                    className = "embed-responsive-item"
                    src = "https://player.vimeo.com/video/462569822"
                    allowfullscreen >
                    < /iframe>
                )
            } <
            /div> <
            /MDBModalBody> <
            /MDBModal>


            <
            MDBModal isOpen = { this.state.isOpenHandle }
            toggle = { this.toggleHandle }
            size = "lg" >
            <
            MDBModalBody >
            <
            div className = "embed-responsive embed-responsive-16by9" > {
                this.state.isOpenHandle && ( <
                    iframe title = "embedsPage"
                    className = "embed-responsive-item"
                    src = "https://player.vimeo.com/video/462571206"
                    allowfullscreen >
                    < /iframe>
                )
            } <
            /div> <
            /MDBModalBody> <
            /MDBModal>


            <
            MDBModal isOpen = { this.state.isOpenClip }
            toggle = { this.toggleClip }
            size = "lg" >
            <
            MDBModalBody >
            <
            div className = "embed-responsive embed-responsive-16by9" > {
                this.state.isOpenClip && ( <
                    iframe title = "embedsPage"
                    className = "embed-responsive-item"
                    src = "https://player.vimeo.com/video/462571348"
                    allowfullscreen >
                    < /iframe>
                )
            } <
            /div> <
            /MDBModalBody> <
            /MDBModal>


            <
            MDBModal centered isOpen = { this.state.isOpenClipmiddle02 }
            toggle = { this.toggleClipmiddle02 }
            size = "lg" >
            <
            MDBModalBody >
            <
            div className = "embed-responsive embed-responsive-16by9" > {
                this.state.isOpenClipmiddle02 && ( <
                    iframe title = "embedsPage"
                    className = "embed-responsive-item"
                    src = "https://player.vimeo.com/video/604848978?h=a29741a9cc"
                    allowfullscreen >
                    < /iframe>
                )
            } <
            /div> <
            /MDBModalBody> <
            /MDBModal>

            <
            MDBModal centered isOpen = { this.state.isOpenClipmiddle01 }
            toggle = { this.toggleClipmiddle01 }
            size = "lg" >
            <
            MDBModalBody >
            <
            div className = "embed-responsive embed-responsive-16by9" > {
                this.state.isOpenClipmiddle01 && ( <
                    iframe title = "embedsPage"
                    className = "embed-responsive-item"
                    src = "https://player.vimeo.com/video/604880677"
                    allowfullscreen >
                    < /iframe>
                )
            } <
            /div> <
            /MDBModalBody> <
            /MDBModal>

            <
            MDBModal centered isOpen = { this.state.isOpenClipleft01 }
            toggle = { this.toggleClipleft01 }
            size = "lg" >
            <
            MDBModalBody >
            <
            div className = "embed-responsive embed-responsive-16by9" > {
                this.state.isOpenClipleft01 && ( <
                    iframe title = "embedsPage"
                    className = "embed-responsive-item"
                    src = "https://player.vimeo.com/video/605530308?h=93b4c43b6e"
                    allowfullscreen >
                    < /iframe>
                )
            } <
            /div> <
            /MDBModalBody> <
            /MDBModal>

            <
            MDBModal centered isOpen = { this.state.isOpenClipright01 }
            toggle = { this.toggleClipright01 }
            size = "lg" >
            <
            MDBModalBody >
            <
            div className = "embed-responsive embed-responsive-16by9" > {
                this.state.isOpenClipright01 && ( <
                    iframe title = "embedsPage"
                    className = "embed-responsive-item"
                    src = "https://player.vimeo.com/video/604937666"
                    allowfullscreen >
                    < /iframe>
                )
            } <
            /div> <
            /MDBModalBody> <
            /MDBModal>


            <
            MDBModal centered isOpen = { this.state.isOpenClipright02 }
            toggle = { this.toggleClipright02 }
            size = "lg" >
            <
            MDBModalBody >
            <
            div className = "embed-responsive embed-responsive-16by9" > {
                this.state.isOpenClipright02 && ( <
                    iframe title = "embedsPage"
                    className = "embed-responsive-item"
                    src = "https://player.vimeo.com/video/604937804"
                    allowfullscreen >
                    < /iframe>
                )
            } <
            /div> <
            /MDBModalBody> <
            /MDBModal>


            <
            MDBModal centered isOpen = { this.state.isOpenClipmiddleimg01 }
            toggle = { this.toggleClipmiddleimg01 }
            id = "forRemoveBG"
            size = "lg" >
            <
            MDBModalBody >
            <
            div className = "centerbox" >
            <
            img src = "images/assets/S__115786235.jpg"
            className = "fiximg" /
            >
            <
            /div> <
            /MDBModalBody> <
            /MDBModal>


            { /*------------------------------------------ state-2 (Left) ----------------------------------------------------------- */ } <
            MDBModal isOpen = { this.state.isOpenleftimage01 }
            toggle = { this.toggleleftimage01 }
            id = "forRemoveBG" >
            <
            MDBModalBody >
            <
            div className = "centerbox" >
            <
            img src = "images/assets/1631520997037.jpg"
            className = "fiximg" /
            >
            <
            /div> <
            /MDBModalBody> <
            /MDBModal>


            <
            MDBModal centered isOpen = { this.state.isOpenleftimage02 }
            toggle = { this.toggleleftimage02 }
            size = "lg" >
            <
            MDBModalBody >
            <
            div className = "centerbox" >
            <
            img src = "images/assets/pn_productall.jpg"
            className = "img-fluid" /
            >
            <
            /div> <
            /MDBModalBody> <
            /MDBModal>



            {
                /*   <MDBModal centered
                            isOpen={this.state.isOpenleftimage03}
                            toggle={this.toggleleftimage03}
                            size="lg"
                            id="forRemoveBG"
                          >
                            <MDBModalBody>
                              <div className="centerbox">
                              <img
                                src="images/assets/Smofkabiven for Pediatric 80x200cm KS 0306-2563-01.jpg"
                                className="fiximg"
                              />      
                              </div>
                            </MDBModalBody>
                          </MDBModal> */
            }

            { /*------------------------------------------ state-2 (Left) ----------------------------------------------------------- */ }



            { /*------------------------------------------ state-3 (Right) ---------------------------------------------------------- */ } <
            MDBModal centered isOpen = { this.state.isOpenrightimage01 }
            toggle = { this.togglerighttimage01 }
            size = "lg" >
            <
            MDBModalBody > {
                /*  <div className="centerbox">
                              <img
                                src="images/assets/Submit - Poster.jpg"
                                className="img-fluid"
                              />      
                              </div> */
            }

            <
            MDBContainer >
            <
            MDBCarousel activeItem = { 1 }
            length = { 2 }
            showControls = { true }
            showIndicators = { true }
            className = "z-depth-1"
            id = "forRemoveBG" >
            <
            MDBCarouselInner >
            <
            MDBCarouselItem itemId = "1" >
            <
            MDBView >
            <
            img className = "d-block w-100 fiximg2"
            src = "images/assets/Submit - Poster 2 kcal.jpg"
            alt = "First slide"

            /
            >
            <
            MDBMask / >
            <
            /MDBView> <
            MDBCarouselCaption >


            <
            /MDBCarouselCaption> <
            /MDBCarouselItem> <
            MDBCarouselItem itemId = "2" >
            <
            MDBView >
            <
            img className = "d-block w-100 fiximg2"
            src = "images/assets/Submit - Poster WPI.jpg"
            alt = "Second slide" /
            >
            <
            MDBMask / >
            <
            /MDBView> <
            MDBCarouselCaption >


            <
            /MDBCarouselCaption> <
            /MDBCarouselItem>


            <
            /MDBCarouselInner> <
            /MDBCarousel> <
            /MDBContainer>




            <
            /MDBModalBody> <
            /MDBModal>









            <
            MDBModal centered isOpen = { this.state.isOpenrightimage02 }
            toggle = { this.togglerighttimage02 }
            size = "lg" >
            <
            MDBModalBody >
            <
            div className = "centerbox" >
            <
            img src = "images/assets/en_productall.jpg"
            className = "img-fluid" /
            >
            <
            /div> <
            /MDBModalBody> <
            /MDBModal>

            <
            MDBModal isOpen = { this.state.isOpenrightimage03 }
            toggle = { this.togglerighttimage03 }
            size = "lg"
            id = "forRemoveBG" >
            <
            MDBModalBody >
            <
            div className = "centerbox" >
            <
            img src = "images/assets/Poster4.jpg"
            className = "fiximg" /
            >
            <
            /div> <
            /MDBModalBody> <
            /MDBModal>

            { /*------------------- state-3 (Right) ------------------------------------ */ } <
            MDBModal centered isOpen = { this.state.isOpenRigthload }
            toggle = { this.toggleisOpenRigthload }
            size = "lg" >
            <
            MDBModalBody >
            <
            div className = "rightbox2" >
            <
            img src = "images/assets/Cover_etosteri.jpg"
            className = "img-fluid" /
            >

            <
            a href = "pdf/Fresenius_Ketosteril A4_01.pdf"
            target = "_blank"
            rel = "noopener noreferrer" >
            <
            img src = "images/button/Button_Kabi-06.png"
            className = "OpenRigthload2" /
            >
            <
            /a>

            <
            /div> <
            /MDBModalBody> <
            /MDBModal>


            <
            MDBModal centered isOpen = { this.state.isOpenleftload }
            toggle = { this.toggleisOpenleftload }
            size = "xl"

            >
            <
            MDBModalBody >


            <
            div class = "container" >
            <
            div class = "row" >
            <
            div class = "col-4 col-md-4 fixbottom " >
            <
            img src = "images/assets/Cover_Final All product adult  update 17 Sep  2020_04.jpg"
            className = "OpenRigthload" /
            >
            <
            div className = "centerbox" >
            <
            a href = "pdf/Final All product adult  update 17 Sep  2020_04.pdf"
            target = "_blank"
            rel = "noopener noreferrer" >
            <
            img src = "images/button/Button_Kabi-06.png"
            className = "OpenLeftload" /
            >
            <
            /a> <
            /div> <
            /div> <
            div class = "col-4 col-md-4 fixbottom" >
            <
            img src = "images/assets/Cover_Parenteral_Nutrition_in_Paeditrices_19.jpg"
            className = "OpenRigthload" /
            >
            <
            div className = "centerbox" >
            <
            a href = "pdf/Parenteral_Nutrition_in_Paeditrices_19.pdf"
            target = "_blank"
            rel = "noopener noreferrer" >
            <
            img src = "images/button/Button_Kabi-06.png"
            className = "OpenLeftload" /
            >
            <
            /a> <
            /div> <
            /div>

            <
            div class = "col-4 col-md-4 fixbottom" >
            <
            img src = "images/assets/Cover_Fresenius_Multivitamins Brochure A4_02.jpg"
            className = "OpenRigthload" /
            >
            <
            div className = "centerbox" >
            <
            a href = "pdf/Fresenius_Multivitamins Brochure A4_02.pdf"
            target = "_blank"
            rel = "noopener noreferrer" >
            <
            img src = "images/button/Button_Kabi-06.png"
            className = "OpenLeftload" /
            >
            <
            /a> <
            /div> <
            /div> <
            /div> <
            /div>







            <
            /MDBModalBody> <
            /MDBModal>


            <
            MDBModal centered isOpen = { this.state.isOpenindex }
            toggle = { this.toggleisOpenindex }
            size = "lg" >
            <
            MDBModalBody >
            <
            div className = "centerbox" >
            <
            img src = "images/assets/Kabi_Popup_Massage_1200x800-01.jpg"
            className = "img-fluid" /
            >
            <
            /div> <
            /MDBModalBody> <
            /MDBModal>





            <
            /div> <
            />
        );
    }
}

export default App;